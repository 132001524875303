namespace $ {
	export class $hyoo_offer extends $mol_book2 {
		
		/**
		 * ```tree
		 * attr * mol_theme \$mol_theme_dark
		 * ```
		 */
		attr() {
			return {
				mol_theme: "$mol_theme_dark"
			}
		}
		
		/**
		 * ```tree
		 * pages /
		 * 	<= Main_page
		 * 	<= Corporate_page
		 * 	<= Private_page
		 * 	<= Dev_page
		 * ```
		 */
		pages() {
			return [
				this.Main_page(),
				this.Corporate_page(),
				this.Private_page(),
				this.Dev_page()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Placeholder null
		 * ```
		 */
		Placeholder() {
			return null as any
		}
		
		/**
		 * ```tree
		 * Details_close $mol_link
		 * 	arg * business null
		 * 	sub / <= Details_close_icon
		 * ```
		 */
		@ $mol_mem
		Details_close() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				business: null as any
			})
			obj.sub = () => [
				this.Details_close_icon()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * corporate_title @ \Corporative clients
		 * ```
		 */
		corporate_title() {
			return this.$.$mol_locale.text( '$hyoo_offer_corporate_title' )
		}
		
		/**
		 * ```tree
		 * Corporate_link $mol_link
		 * 	arg * business \corporate
		 * 	title <= corporate_title
		 * ```
		 */
		@ $mol_mem
		Corporate_link() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				business: "corporate"
			})
			obj.title = () => this.corporate_title()
			
			return obj
		}
		
		/**
		 * ```tree
		 * private_title @ \Private business
		 * ```
		 */
		private_title() {
			return this.$.$mol_locale.text( '$hyoo_offer_private_title' )
		}
		
		/**
		 * ```tree
		 * Private_link $mol_link
		 * 	arg * business \private
		 * 	title <= private_title
		 * ```
		 */
		@ $mol_mem
		Private_link() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				business: "private"
			})
			obj.title = () => this.private_title()
			
			return obj
		}
		
		/**
		 * ```tree
		 * dev_title @ \Developers
		 * ```
		 */
		dev_title() {
			return this.$.$mol_locale.text( '$hyoo_offer_dev_title' )
		}
		
		/**
		 * ```tree
		 * Dev_link $mol_link
		 * 	arg * business \dev
		 * 	title <= dev_title
		 * ```
		 */
		@ $mol_mem
		Dev_link() {
			const obj = new this.$.$mol_link()
			
			obj.arg = () => ({
				business: "dev"
			})
			obj.title = () => this.dev_title()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Menu $mol_list rows /
		 * 	<= Corporate_link
		 * 	<= Private_link
		 * 	<= Dev_link
		 * ```
		 */
		@ $mol_mem
		Menu() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => [
				this.Corporate_link(),
				this.Private_link(),
				this.Dev_link()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Main_page $mol_page
		 * 	title \HyOO - приложения для бизнеса
		 * 	body / <= Menu
		 * ```
		 */
		@ $mol_mem
		Main_page() {
			const obj = new this.$.$mol_page()
			
			obj.title = () => "HyOO - приложения для бизнеса"
			obj.body = () => [
				this.Menu()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Corporate_page $hyoo_offer_corporate
		 * 	title <= corporate_title
		 * 	tools / <= Details_close
		 * ```
		 */
		@ $mol_mem
		Corporate_page() {
			const obj = new this.$.$hyoo_offer_corporate()
			
			obj.title = () => this.corporate_title()
			obj.tools = () => [
				this.Details_close()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Private_page $hyoo_offer_private
		 * 	title <= private_title
		 * 	tools / <= Details_close
		 * ```
		 */
		@ $mol_mem
		Private_page() {
			const obj = new this.$.$hyoo_offer_private()
			
			obj.title = () => this.private_title()
			obj.tools = () => [
				this.Details_close()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Dev_page $hyoo_offer_dev
		 * 	title <= dev_title
		 * 	tools / <= Details_close
		 * ```
		 */
		@ $mol_mem
		Dev_page() {
			const obj = new this.$.$hyoo_offer_dev()
			
			obj.title = () => this.dev_title()
			obj.tools = () => [
				this.Details_close()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Details_close_icon $mol_icon_cross
		 * ```
		 */
		@ $mol_mem
		Details_close_icon() {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
	}
	
}

