namespace $ {
	export class $hyoo_offer_corporate extends $mol_page {
		
		/**
		 * ```tree
		 * body / <= Text
		 * ```
		 */
		body() {
			return [
				this.Text()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * text \
		 * 	\# Преимущества
		 * 	\
		 * 	\- **Быстрая** разработка и внедрение (Continuous Delivery).
		 * 	\- Синхронные релизы под **все платформы** (веб, десктоп, планшет, смартфон).
		 * 	\- Единый **брендированный дизайн** и пользовательский опыт ваших приложений на всех платформах и размерах экрана.
		 * 	\- Формирование требований и написание технического задания **всего за 50 000 ₽**.
		 * 	\
		 * 	\# Кейсы
		 * 	\
		 * 	\- Корпоративный портал с лентой новостей, каталогом документов, каталогом сотрудников, менеджером задач, опросником и прочими мини приложениями
		 * 	\- Серия приложений для разных типов клиентов и сотрудников банка (частные лица, юридические лица, менеджеры)
		 * 	\- Приложения управления товаром (приём товара, списание, подтверждение контролёром, аналитика для руководства)
		 * 	\
		 * 	\> [Назначить деловую встречу](mailto:order-corporate@hyoo.ru)
		 * 	\> [Примеры публичных приложений](https://apps.hyoo.ru)
		 * ```
		 */
		text() {
			return "# Преимущества\n\n- **Быстрая** разработка и внедрение (Continuous Delivery).\n- Синхронные релизы под **все платформы** (веб, десктоп, планшет, смартфон).\n- Единый **брендированный дизайн** и пользовательский опыт ваших приложений на всех платформах и размерах экрана.\n- Формирование требований и написание технического задания **всего за 50 000 ₽**.\n\n# Кейсы\n\n- Корпоративный портал с лентой новостей, каталогом документов, каталогом сотрудников, менеджером задач, опросником и прочими мини приложениями\n- Серия приложений для разных типов клиентов и сотрудников банка (частные лица, юридические лица, менеджеры)\n- Приложения управления товаром (приём товара, списание, подтверждение контролёром, аналитика для руководства)\n\n> [Назначить деловую встречу](mailto:order-corporate@hyoo.ru)\n> [Примеры публичных приложений](https://apps.hyoo.ru)"
		}
		
		/**
		 * ```tree
		 * Text $mol_text text <= text
		 * ```
		 */
		@ $mol_mem
		Text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.text()
			
			return obj
		}
	}
	
}

