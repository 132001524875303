namespace $ {
	export class $mol_button_typed extends $mol_button {
		
		/**
		 * ```tree
		 * minimal_height 40
		 * ```
		 */
		minimal_height() {
			return 40
		}
		
		/**
		 * ```tree
		 * minimal_width 40
		 * ```
		 */
		minimal_width() {
			return 40
		}
	}
	
}

