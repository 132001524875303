namespace $ {
	export class $hyoo_offer_dev extends $mol_page {
		
		/**
		 * ```tree
		 * body / <= Text
		 * ```
		 */
		body() {
			return [
				this.Text()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * text \
		 * 	\# Есть руки, нужны заказы?
		 * 	\
		 * 	\- Мы **поделимся** с вами заказами. Сначала небольшими, потом крупными.
		 * 	\- Мы **научим** вас нашим технологиям быстрой и качественной разработки.
		 * 	\- Мы дадим вам **чёткие требования** в виде приёмочных тестов.
		 * 	\
		 * 	\# Есть заказы, нужны руки?
		 * 	\
		 * 	\- Мы возьмём на себя излишек заказов, поделившись прибылью.
		 * 	\- Мы поможем оптимизировать ваши процессы и технологии разработки.
		 * 	\
		 * 	\> [Стать партнёром](mailto:offer-dev@hyoo.ru)
		 * 	\> [Примеры публичных приложений](https://apps.hyoo.ru)
		 * ```
		 */
		text() {
			return "# Есть руки, нужны заказы?\n\n- Мы **поделимся** с вами заказами. Сначала небольшими, потом крупными.\n- Мы **научим** вас нашим технологиям быстрой и качественной разработки.\n- Мы дадим вам **чёткие требования** в виде приёмочных тестов.\n\n# Есть заказы, нужны руки?\n\n- Мы возьмём на себя излишек заказов, поделившись прибылью.\n- Мы поможем оптимизировать ваши процессы и технологии разработки.\n\n> [Стать партнёром](mailto:offer-dev@hyoo.ru)\n> [Примеры публичных приложений](https://apps.hyoo.ru)"
		}
		
		/**
		 * ```tree
		 * Text $mol_text text <= text
		 * ```
		 */
		@ $mol_mem
		Text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.text()
			
			return obj
		}
	}
	
}

