namespace $ {
	export class $hyoo_offer_private extends $mol_page {
		
		/**
		 * ```tree
		 * body / <= Text
		 * ```
		 */
		body() {
			return [
				this.Text()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * text \
		 * 	\# Преимущества
		 * 	\
		 * 	\- Приложения заточенные именно **под ваш бизнесс**.
		 * 	\- Приложение под ключ **от 50 000 ₽**.
		 * 	\- Одно приложение сразу под **все платформы** (Progressive Web Apps).
		 * 	\
		 * 	\# Кейсы
		 * 	\
		 * 	\- Информационное сопровождение мероприятий: контакты участников, карта, расписание, геймификация
		 * 	\- Цифровой помощник в ресторане: вызов оффицианта, акции, каталог блюд и немедленный их заказ, немедленная оплата с привязанной карты
		 * 	\- Учёт достижений группового движения к целям: приход, расход, burn down chart
		 * 	\
		 * 	\> [Запросить оценку своего кейса](mailto:order-private@hyoo.ru)
		 * 	\> [Примеры публичных приложений](https://apps.hyoo.ru)
		 * ```
		 */
		text() {
			return "# Преимущества\n\n- Приложения заточенные именно **под ваш бизнесс**.\n- Приложение под ключ **от 50 000 ₽**.\n- Одно приложение сразу под **все платформы** (Progressive Web Apps).\n\n# Кейсы\n\n- Информационное сопровождение мероприятий: контакты участников, карта, расписание, геймификация\n- Цифровой помощник в ресторане: вызов оффицианта, акции, каталог блюд и немедленный их заказ, немедленная оплата с привязанной карты\n- Учёт достижений группового движения к целям: приход, расход, burn down chart\n\n> [Запросить оценку своего кейса](mailto:order-private@hyoo.ru)\n> [Примеры публичных приложений](https://apps.hyoo.ru)"
		}
		
		/**
		 * ```tree
		 * Text $mol_text text <= text
		 * ```
		 */
		@ $mol_mem
		Text() {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.text()
			
			return obj
		}
	}
	
}

